import { GatsbySeo } from 'gatsby-plugin-next-seo'
import PoliticaEntrega from 'src/components/servicos/ServicesPage/PoliticaEntrega'

function Page() {
  return (
    <>
      <GatsbySeo
        language="pt-br"
        title="Política de entrega | Decathlon"
        noindex
        nofollow
      />
      <PoliticaEntrega />
    </>
  )
}

export default Page
